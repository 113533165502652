/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.title {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
  font-weight: 600;
  color: #14837B;
}
.mobile .title {
  font-size: 1em;
}
.service_job-parts-pane::v-deep .ant-collapse-content-box {
  background: #e6f7ff;
}
.main-header {
  text-align: center;
}
.firmware-update {
  display: flex;
  flex-direction: row;
}
.repair-section-title {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  text-decoration: underline;
  margin-right: 5px;
  font-size: 1.1em;
  color: #D22027;
  margin-bottom: 12px;
}
.background {
  background: white;
  padding: 10px 10px 50px;
}
.mobile .background {
  padding: 12px 16px;
}
.page-action-right {
  float: right;
  margin-bottom: 8px;
}
