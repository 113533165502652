/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.next-button {
  margin: 10px 0 0 0;
}
.title {
  font-size: 1.3em;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
  color: #14837B;
}
.mobile .title {
  font-size: 1em;
}
.input-block {
  display: inline-block;
  margin-right: 8px;
}
.mobile .input-block {
  display: block;
  margin-bottom: 4px;
  margin-right: 0;
}
.modal-style {
  text-align: center;
}
.modal-body {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.select-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.old-firmware {
  margin-right: 5px;
}
